declare global {
  const loadSpin360: (divId: string, options: any) => void;
}

const getSpinScriptUrl = () => {
  return "https://assets.mobile.production.ldv-svcs.live/p/0.7.latest/spin360.lite.js";
};

export const installSpin = (vin: string, spinDivSelector: string) => {
  const node = document.querySelector(spinDivSelector);
  if (!node) {
    console.warn("Snapcell spin selector invalid: " + spinDivSelector);
    return;
  }

  let nodeId = node.id;
  if (!nodeId) {
    nodeId = `spin_div_${Math.random()}`;
    node.id = nodeId;
  }

  const script = document.createElement("script");
  script.onload = () => {
    node.setAttribute("style", "margin:0 auto;width:100%;max-width:640px;");
    loadSpin360(nodeId, { portal: "KLLOF", vin });
  };
  script.src = getSpinScriptUrl();

  document.head.appendChild(script);
};

export const installSpinWithDDC = async (DDC_API: any) => {
  const vehicleDDCInfo = await DDC_API.utils.getVehicleData();
  if (vehicleDDCInfo.length && vehicleDDCInfo[0].vin) {
    await DDC_API.loadJS(getSpinScriptUrl());
    DDC_API.insert("vehicle-media", (elem, meta) => {
      const div = document.createElement("div");
      div.id = "spin-div";
      DDC_API.append(elem, div);
      loadSpin360("spin-div", { portal: "kllof", vin: "WP0BB2A91PS233881" });
    });
  }
};
